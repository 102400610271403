<template>
  <div class='main has-header has-footer'>
    <mt-header fixed title="任务详情">
      <router-link to="" slot="left">
        <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>
    </mt-header>
    <div class="wrap summary clearfix">
      <div class="top-line clearfix">
        <div class="col col-left">
          <h4 class="txt-black">{{task.taskName}}</h4>
          <h5 class="txt-red">{{task.priceDesc}}</h5>
        </div>
        <div class="col col-right">
          <span v-if="task.userTaskState== 0" class="tag txt-red border-red">未领取</span>
          <span v-else-if="task.userTaskState== 1" class="tag txt-orange border-orange">未签约</span>
        </div>
      </div>
      <div class="line"><span class="txt-gray-lighter lable">发包企业：</span><span class="txt-gray-light info">{{task.companyName}}</span></div>
      <div class="line"><span class="txt-gray-lighter lable">发包时间：</span><span class="txt-gray-light info">{{task.createDate}}</span></div>
    </div>
    <hr class="space">
    <div class="wrap intro clearfix">
      <h4 class="txt-black">任务描述</h4>
      <div class="detail">
        {{task.taskDesc}}
      </div>
    </div>
  

  <div class="footer-bt">
        <mt-button 
        v-if="task.userTaskState == 0" 
        type="danger" 
        size="large" 
        @click="handleToReceive">领取任务</mt-button>
        <mt-button 
        v-else-if="task.userTaskState == 1" 
        type="danger" 
        size="large" 
        @click="handleToSign">去签约</mt-button>
        <mt-button 
        v-else
        type="danger" 
        size="large" 
        @click="handleToContract">查看签约</mt-button>
      </div>
  </div>
</template>

<script>
  import { getTaskDetail,receiveTask } from "@api/tasks";
  import { formatDate} from "@utils/common";
  export default {
    components: {},
    props: ['id'],
    data() {
      //这里存放数据
      return {
        task: {}
      };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
      handleToReceive () {
        this.handleTask('/task/to-sign/'+this.id)
      },
      handleToSign () {
        this.$router.replace('/task/to-sign/'+this.id)
        // this.handleTask('/task/to-sign/'+this.id)
      },
      handleToContract () {
        this.$router.push('/task/mySigns/'+this.id)
      },
      handleTask (url) {
        let data = {
          taskId : this.id - 0,
          state : this.task.userTaskState-0+1 || 0,
          userId : this.$store.getters.getUser.userId,
          source: 1
        }
        receiveTask(data).then((res) => {
          this.task.userTaskState = res.data
          this.$router.push(url)
        }).catch((err) => {
        })
      }
    },
    mounted() {
      let data = {
        taskId: this.id
      }
      getTaskDetail(data).then((res) => {
        this.task = res.data
        this.task.createDate = formatDate(new Date(res.data.createDate),'yyyy-MM-dd HH:mm:ss')
      })
    }
  }
</script>

<style lang='less' scoped>
  .wrap {
    padding: 20px;
    background-color: #fff;
    position: relative;
  }
  
  .summary {
   
    min-height: 100px;
    .top-line {
      overflow: hidden;
      min-height:4rem;
      border-bottom:1px solid #e8e8e8;
      margin-bottom:15px;
      padding-bottom:12px;
      .col {
        float: left;
        min-width: 2rem;
        &.col-left {
          width: 70%;
          line-height: 2rem;
          h4,
          h5 {
            margin: 0;
            word-break: break-all;
          }
        }
        &.col-right {
          width: 30%;
          text-align: right;
        }
      }
    }
    .tag{
      display: inline-block;
      padding:3px 8px;
      font-size: 0.8rem;
      border:2px solid;
    }
    .line{
      font-size: 0.8rem;
      margin-top:8px;
      .label{
        margin-right:8px;
      }
    }
  }
  
  .intro {
  
    .detail{
  padding-bottom:130px;
    }
  }
</style>