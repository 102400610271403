import { render, staticRenderFns } from "./task-detail.vue?vue&type=template&id=e7f04030&scoped=true&"
import script from "./task-detail.vue?vue&type=script&lang=js&"
export * from "./task-detail.vue?vue&type=script&lang=js&"
import style0 from "./task-detail.vue?vue&type=style&index=0&id=e7f04030&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7f04030",
  null
  
)

export default component.exports